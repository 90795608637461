import React, { useState } from "react";
import { NavLink } from "react-router-dom";
// import Avatar from "react-avatar";
import { FiMinus } from "react-icons/fi";
import "../styles/ViewLayout.component.scss";
// import { AiOutlineFolder } from "react-icons/ai";
import { RiStackLine } from "react-icons/ri";
import ProfileDropdown from "./ProfileDropdown";
import KeycloakClient from "../hooks/keycloak.hook";
// import { logOut } from "../utils/auth.utils";
export function ViewLayoutComponent({ children }) {
    const [showSmSideNav, setShowSmSideNav] = useState(false);

    function toggleSideNav(state: boolean) {
        setShowSmSideNav(state);
    }
    function logoutUser() {
        KeycloakClient.getInstance().logout();
    }

    return (
        <div className="view-layout h-screen w-screen relative">
            <div className="header-content">
                <div className="">
                    <ProfileDropdown />
                </div>
                <div className="">
                    {!showSmSideNav && (
                        <button
                            onClick={() => toggleSideNav(true)}
                            className="sm-toggle-sidenav"
                        >
                            <FiMinus className="landing-nav-links-icon" />
                        </button>
                    )}
                </div>
            </div>
            <div
                className={`landing-page-wrapper ${
                    showSmSideNav ? "side-open" : ""
                }`}
            >
                {children}
            </div>
            <div
                className={`mobile-nav-links-container ${
                    showSmSideNav ? "side-open" : ""
                }`}
            >
                <button
                    onClick={() => toggleSideNav(false)}
                    className="close-btn"
                ></button>
                <ul className="main-links">
                    {/* <li>
                        <button className="landing-nav-link">
                            <FiRefreshCw />
                            Personal
                        </button>
                    </li>
                    <li className="divider"></li>
                    <li>
                        <button id="about-link" className="landing-nav-link">
                            <AiOutlineFolder />
                            Folders
                        </button>
                    </li> */}
                    <li>
                        <NavLink to={"/"}>
                            <button
                                id="pricing-link"
                                className="landing-nav-link"
                            >
                                <RiStackLine />
                                Canvas
                            </button>
                        </NavLink>
                    </li>
                </ul>

                <ul className="bottom-links">
                    <li>
                        <a target="_blank" href="#">
                            Profile
                        </a>
                    </li>
                    <li>
                        <a
                            target="_blank"
                            href="#"
                            onClick={logoutUser}
                            rel="noreferrer"
                        >
                            Logout
                        </a>
                    </li>
                    <li>
                        <button>Terms</button>
                    </li>
                </ul>
                <div className="mobile-nav-links-footer">
                    <p className="">&copy; 2023 NeoWorlder, Inc.</p>
                </div>
            </div>
        </div>
    );
}
