import { AiOutlineLoading3Quarters } from "react-icons/ai";

interface SpinnerProps {
    size?: string;
}

const Spinner: React.FC<SpinnerProps> = ({ size = "18px" }) => {
    return (
        <AiOutlineLoading3Quarters
            size={size}
            className="animate-spin duration-500 bg-neutral-900"
        />
    );
};

export default Spinner;
