import React, { useState, useRef, useEffect } from "react";
import "../styles/InfiniteCanvas.component.scss";
import { ScaleContext } from "../utils/ScaleContext";
import { LordIcon } from "./icons/LordIcon";

interface ContentProps {
    offset: { x: number; y: number };
    scale: number;
    children: React.ReactNode;
}

const Content = React.forwardRef<HTMLDivElement, ContentProps>(
    ({ offset, scale, children }, ref) => {
        const stopPropagation = (e: React.MouseEvent) => {
            e.stopPropagation();
        };

        return (
            <div ref={ref} className="content">
                <div
                    className="checkeredBackground"
                    style={{
                        backgroundSize: `${20 * scale}px ${20 * scale}px`,
                        backgroundPosition: `${offset.x}px ${offset.y}px`
                    }}
                >
                    <div
                        className="zoomContainer"
                        style={{
                            transform: `translate(${offset.x}px, ${offset.y}px) scale(${scale})`
                        }}
                        onMouseDown={stopPropagation}
                        onMouseMove={stopPropagation}
                    >
                        {children}
                    </div>
                </div>
            </div>
        );
    }
);

Content.displayName = "Content";

const InfiniteCanvas = ({ children }: { children: React.ReactNode }) => {
    const canvasRef = useRef<HTMLDivElement | null>(null);
    const contentRef = useRef<HTMLDivElement | null>(null);

    const [offset, setOffset] = useState({ x: 0, y: 0 });
    const [dragging, setDragging] = useState(false);
    const [lastPos, setLastPos] = useState({ x: 0, y: 0 });
    const [scale, setScale] = useState(1);

    // This is a hack to center the canvas on load.
    useEffect(() => {
        // Check if both refs are valid
        if (contentRef.current && canvasRef.current) {
            const contentBounds = contentRef.current.getBoundingClientRect();
            const canvasBounds = canvasRef.current.getBoundingClientRect();

            // Calculate the offsets to center the content
            const xOffset =
                (canvasBounds.width - contentBounds.width * scale) / 2;
            const yOffset =
                (canvasBounds.height - contentBounds.height * scale) / 2;

            // Set the calculated offsets
            setOffset({ x: xOffset, y: yOffset });
        }
    }, [scale]); // This effect depends on 'scale' since the centering calculation is affected by the scaling factor

    const handleMouseDown = (e: React.MouseEvent) => {
        setLastPos({ x: e.clientX, y: e.clientY });
        setDragging(true);
    };

    const handleMouseUp = () => {
        setDragging(false);

        const adjustedX = Math.round(offset.x / gridSize) * gridSize;
        const adjustedY = Math.round(offset.y / gridSize) * gridSize;
        setOffset({ x: adjustedX, y: adjustedY });
    };

    const handleMouseMove = (e: React.MouseEvent) => {
        e.preventDefault();

        if (dragging && lastPos) {
            const deltaX = e.clientX - lastPos.x;
            const deltaY = e.clientY - lastPos.y;

            setOffset(prev => ({ x: prev.x + deltaX, y: prev.y + deltaY }));
            setLastPos({ x: e.clientX, y: e.clientY });
        }
    };

    const handleCenterClick = () => {
        // 1. Reset the scale to default
        const defaultScale = 1;
        setScale(defaultScale);

        // 2. Re-center the content
        if (contentRef.current && canvasRef.current) {
            const contentBounds = contentRef.current.getBoundingClientRect();
            const canvasBounds = canvasRef.current.getBoundingClientRect();

            const xOffset =
                (canvasBounds.width - contentBounds.width * defaultScale) / 2;
            const yOffset =
                (canvasBounds.height - contentBounds.height * defaultScale) / 2;

            setOffset({ x: xOffset, y: yOffset });
        }
    };

    const handleMouseWheel = (e: any) => {
        // e.preventDefault();

        // 1. Calculate the new scale.
        const zoomIncrement = 0.1;
        let newScale = scale;
        if (e.deltaY < 0) {
            newScale = Math.min(scale + zoomIncrement, 3);
        } else {
            newScale = Math.max(scale - zoomIncrement, 0.5);
        }

        // 2. Find the center of the canvas (viewport).
        const currentTargetRect = e.currentTarget.getBoundingClientRect();
        const centerX = currentTargetRect.width / 2;
        const centerY = currentTargetRect.height / 2;

        // 3. Compute how much the center has "moved" due to the scaling operation.
        const diffX = centerX - offset.x;
        const diffY = centerY - offset.y;
        const newOffsetX = centerX - diffX * (newScale / scale);
        const newOffsetY = centerY - diffY * (newScale / scale);

        // 4. Adjust the offset so that the canvas remains centered.
        setOffset({
            x: newOffsetX,
            y: newOffsetY
        });

        setScale(newScale);
    };

    const gridSize = 20 * scale; // This is the size of each square in the checkered background

    return (
        <div className="infiniteCanvasWrapper">
            <button onClick={handleCenterClick} className="centerButton">
                <LordIcon
                    src="https://cdn.lordicon.com/gdjyhaga.json"
                    trigger="hover"
                    colors={{ primary: "#121331", secondary: "#333" }}
                    size={30}
                />
            </button>
            <div
                className="infiniteCanvas"
                ref={canvasRef}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onWheel={handleMouseWheel}
                onDragStart={e => e.preventDefault()}
            >
                <ScaleContext.Provider value={scale}>
                    <Content ref={contentRef} offset={offset} scale={scale}>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                gap: "30px",
                                width: `${gridSize}px`, // Set the width and height of the container
                                height: `${gridSize}px` // to match the size of each square in the checkered pattern
                            }}
                        >
                            {children}
                        </div>
                    </Content>
                </ScaleContext.Provider>
            </div>
        </div>
    );
};

export default InfiniteCanvas;
