import { useEffect } from "react";
import KeycloakClient from "../hooks/keycloak.hook";
import { useHttpClient } from "../utils/http.utils";
import { useDispatch, useSelector } from "react-redux";
import {
    selectAuthState,
    setAuthState,
    setAuthUser
} from "../store/slices/auth.slice";
import { type UserProfile } from "../models/user.profile";

interface KeycloakWrapperProps {
    children: React.ReactNode;
}

const KeycloakWrapper: React.FC<KeycloakWrapperProps> = ({ children }) => {
    const authenticated = useSelector(selectAuthState);
    const { fetchUserCredentialsApi } = useHttpClient();
    const dispatch = useDispatch();

    useEffect(() => {
        (async () => {
            KeycloakClient.getInstance().addSuccessCallback(() => {
                dispatch(setAuthState(true));
            });
            await KeycloakClient.getInstance().init();
        })();
    }, [dispatch]);

    useEffect(() => {
        if (authenticated) {
            (async () => {
                const authUser =
                    await KeycloakClient.getInstance().getAuthProfile();

                await fetchUserCredentialsApi(authUser?.email);

                const authUserData: UserProfile = {
                    email: authUser?.email || "",
                    authUserId: authUser?.id || "",
                    isActive: true
                };

                if (authUserData.authUserId) {
                    dispatch(setAuthState(true));
                    dispatch(setAuthUser(authUserData));
                }
            })();
        }
    }, [authenticated, dispatch]);

    if (!authenticated) {
        return (
            <div className="h-screen w-screen flex justify-center items-center">
                <div className="max-w-lg relative">
                    <div className="">
                        <img
                            src={
                                "https://assets.neoworlder.com/img/v1/lifelab_logo_black.png"
                            }
                            alt={"@NeoWorlder"}
                            className="rounded-[4px] w-auto h-[40px] mx-auto"
                        />
                    </div>
                    <div className="mt-4">
                        <p className="text-base text-center text-neutral-700">
                            Opening the Life Lab Interactive Canvas..
                        </p>
                    </div>
                </div>
            </div>
        );
    }

    return <>{children}</>;
};

export default KeycloakWrapper;
