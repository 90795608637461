import { useEffect, useState } from "react";
import Spinner from "../components/ui/spinner";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ROUTES } from "../routes";
import { setActiveOrganizationId } from "../store/slices/account.slice";

export default function AppInitializerView() {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [error, setError] = useState<string>("");
    const dispatch = useDispatch();

    useEffect(() => {
        // if (authenticated) {
        //     navigate(ROUTES.PERSONAS);
        // }
        const activeOrg = searchParams.get("vendor");
        if (!!activeOrg && typeof activeOrg === "string") {
            window.localStorage.setItem("active_org", activeOrg);
            // Set active organization in state
            dispatch(setActiveOrganizationId(activeOrg));
            // Navigate to other UI
            navigate(ROUTES.ROOT);
        } else {
            // TODO: SHOW ERROR WITH LOADING
            setError(
                "Sorry we cannot allow you in, link you inputted my be invalid, Please make sure to only click the link in the NeoWorlder platform."
            );
        }
    }, [dispatch, navigate, searchParams]);

    return (
        <main className="min-h-screen flex flex-col items-center pt-80 min-w-screen px-10 ">
            <div className="">
                <img
                    src={
                        "https://assets.neoworlder.com/img/v1/lifelab_logo_black.png"
                    }
                    alt={"@NeoWorlder"}
                    className="rounded-[4px] w-auto h-[40px]"
                />
            </div>
            <div className="mt-4">
                <p className="text-base text-center text-neutral-700">
                    Opening the Life Lab Interactive Canvas
                </p>
            </div>
            <div className="mt-3">
                {error ? (
                    <div className="rounded p-3 max-w-lg bg-red-50 text-red-500 border border-red-500">
                        <h4 className="font-semibold">Something went wrong</h4>
                        <p>{error}</p>
                    </div>
                ) : (
                    <div className="loader flex gap-2 justify-normal items-center text-muted-foreground">
                        <Spinner />
                        <p>Please hold on while we are getting the canvas</p>
                    </div>
                )}
            </div>
        </main>
    );
}
